<template>
  <div class="box">
    <router-link to="/company-calendar" class="back">
      <Icon type="ios-arrow-back" />
      <span>Return to Company Calendar</span>
    </router-link>

    <Modal v-model="acceptModal" title="Accept meeting" footer-hide>
      <Form ref="form" :model="form">
        <FormItem label="Users" :rules="required" prop="user">
          <Select filterable multiple v-model="form.user" :max-tag-count="3">
            <Option v-for="item in members" :value="item.id" :key="item.id"
              >{{ item.first_name }} {{ item.last_name }}</Option
            >
          </Select>
        </FormItem>

        <FormItem>
          <Button
            :loading="acceptMeetingLoading"
            type="primary"
            @click="acceptMeeting"
            >Submit</Button
          >
        </FormItem>
      </Form>
    </Modal>

    <div class="pd-20">
      <div class="filter">
        <div class="item filter-type">
          <div class="tip">
            FILTER BY
          </div>

          <Select v-model="filter.state">
            <Option value="all">All</Option>
            <Option value="0">Pending review</Option>
            <Option value="1">Approved</Option>
            <Option value="-1">Failed review</Option>
          </Select>
        </div>

        <!-- <div class="item">
        <div class="tip"></div>
        <router-link to="/factories/create">
          <Button type="primary" icon="md-add" ghost>Add Factory</Button>
        </router-link>
      </div> -->
      </div>
      <Table :loading="loading" :columns="columns" :data="list">
        <template slot-scope="{ row: item }" slot="status">
          <div v-if="item.state === 1">
            <Tag color="success">approved</Tag>
          </div>
          <div v-if="item.state === -1">
            <Tag color="error">rejected</Tag>
          </div>
          <div v-if="item.state === 0">
            <Tag>pending</Tag>
          </div>
        </template>

        <template slot-scope="{ row: item }" slot="date">
          <span>{{ item.local_start_time }}</span>
          <span>~</span>
          <span>{{ item.local_end_time }}</span>
        </template>

        <template slot-scope="{ row: item }" slot="action">
          <div v-if="item.state === 0">
            <Button size="small" @click="chooseMeeting(item)">Accept</Button>
            <Divider type="vertical" />

            <Poptip
              transfer
              confirm
              title="Are you sure you want to reject this item?"
              @on-ok="rejectMeeting(item)"
            >
              <Button size="small" type="error">Reject</Button>
            </Poptip>
          </div>
        </template>
      </Table>

      <div class="page">
        <Page
          :page-size="limit"
          :page-size-opts="[5, 10, 15, 20]"
          :total="total"
          show-sizer
          show-elevator
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
const { _queryAllMeeting, _reviewMeeting, _queryAvailableUser } = api;

export default {
  created() {
    this.queryAllMeeting();
  },
  watch: {
    "filter.state"(newValue) {
      if (newValue === "all") {
        this.state = undefined;
      } else {
        this.state = newValue;
      }
      this.page = 1;
      this.queryAllMeeting();
    }
  },

  data() {
    return {
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      members: [],
      form: {
        user: []
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      acceptModal: false,
      acceptMeetingLoading: false,

      filter: {
        state: "all"
      },
      state: undefined,

      columns: [
        {
          title: "Status",
          slot: "status"
        },
        {
          title: "Title",
          key: "title"
        },
        {
          title: "Description",
          key: "description"
        },
        {
          title: "Date",
          slot: "date"
        },
        {
          title: "Action",
          slot: "action"
        }
      ]
    };
  },

  methods: {
    chooseMeeting(item) {
      this.id = item.id;
      this.acceptModal = true;

      const start_time = moment(item.start_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const end_time = moment(item.end_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      _queryAvailableUser({
        start_time,
        end_time
      })
        .then(response => {
          const { data } = response;
          this.members = data;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    acceptMeeting() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.acceptMeetingLoading = true;

          _reviewMeeting({
            id: this.id,
            state: 1,
            user: [...this.form.user]
          })
            .then(() => {
              this.$Message.success("success");
              this.members = [];
              this.acceptModal = false;
              this.queryAllMeeting(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.acceptMeetingLoading = false;
            });
        }
      });
    },

    rejectMeeting(item) {
      _reviewMeeting({
        id: item.id,
        state: -1
      })
        .then(() => {
          this.$Message.success("success");
          this.queryAllMeeting(); // refresh
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    queryAllMeeting() {
      this.loading = true;

      _queryAllMeeting({
        state: this.state,
        page: this.page,
        limit: this.limit,
        id: this.id
      })
        .then(response => {
          this.loading = false;
          const { data } = response;
          this.list = data.data.map(item => {
            item.local_start_time = moment
              .utc(item.start_time)
              .local()
              .format("YYYY-MM-DD HH:mm");

            item.local_end_time = moment
              .utc(item.end_time)
              .local()
              .format("YYYY-MM-DD HH:mm");
            return item;
          });
          this.total = data.total;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryAllMeeting();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryAllMeeting();
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.box {
  .filter {
    display: flex;
    margin-bottom: 20px;
    .item {
      margin-right: 10px;
    }
    .filter-filter {
      width: 300px;
    }
    .filter-type {
      width: 150px;
    }
    .tip {
      height: 24px;
      color: #ccc;
      font-size: 12px;
    }
  }
  .page {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
